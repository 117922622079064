document.addEventListener('app-connect', () => {
  if (document.querySelector('[data-show-client-message-form]')) {
    const messageForm = document.querySelector('[data-client-message-form-holder]')
    const newMessageBtn = document.querySelector('[data-show-client-message-btn]')
    const newMessageBtnHolder = document.querySelector('[data-show-client-message-btn-holder]')
    newMessageBtn.addEventListener('click', () => {
      messageForm.classList.remove('hidden')
      newMessageBtnHolder.classList.add('hidden')
      document.getElementById('clients_ask_question_form_body').focus()
    }, false)
  }

  if (document.querySelector('[data-hide-client-message-form]')) {
    const messageForm = document.querySelector('[data-client-message-form-holder]')
    const newMessageBtnHolder = document.querySelector('[data-show-client-message-btn-holder]')
    document.querySelector('[data-hide-client-message-form]').addEventListener('click', e => {
      e.preventDefault()
      messageForm.classList.add('hidden')
      newMessageBtnHolder.classList.remove('hidden')
    }, false)
  }
})
